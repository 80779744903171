import React from "react"

import SEO from "../components/SEO"

import Layout from "../layout"

import PrivacyStatement from "../containers/Legal/PrivacyStatement"

function Privacy() {
  return (
    <Layout>
      <SEO
        title="Datenschutz"
        keywords={[
          `Into Numbers`,
          `Data Science`,
          `Web Development`,
          `Digitalisierung`,
          `Trainings`,
          `Business Intelligence`,
          `Datenschutz`,
        ]}
      />
      <PrivacyStatement />
    </Layout>
  )
}

export default Privacy
