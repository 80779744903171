import styled from "styled-components"

import ContentRow from "../../components/ContentRow"

const LegalContentRow = styled(ContentRow)`
  font-size: 14px;
`
const LegalLink = styled.a`
  color: ${props => props.theme.color.font.regular};
`

const LegalParagraph = styled.p`
  margin: ${props => props.theme.spaces.stack.l};
  text-align: justify;
`

const LegalHeadlines = styled.h2`
  font-size: 17px;
  text-transform: uppercase;
  margin: ${props => props.theme.spaces.stack.xxs};
`

const LegalSubHeadlines = styled.h3`
  font-size: 15px;
  text-transform: uppercase;
  margin: ${props => props.theme.spaces.stack.xxs};
`

export {
  LegalContentRow,
  LegalLink,
  LegalParagraph,
  LegalHeadlines,
  LegalSubHeadlines,
}
