import React from "react"

// import Image from "../components/image"
// import SEO from "../components/seo"
import useTheme from "../../layout/theme/useTheme"

import {
  LegalContentRow,
  LegalParagraph,
  LegalLink,
  LegalHeadlines,
  LegalSubHeadlines,
} from "./styled-legal"

function PrivacyStatement() {
  const { spaces, color } = useTheme()
  return (
    <>
      <LegalContentRow>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Wir haben diese Datenschutzerkl&auml;rung verfasst, um Ihnen
          gem&auml;&szlig; der Vorgaben der Datenschutz-Grundverordnung (EU)
          2016/679 und dem{" "}
          <LegalLink
            href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=211094248"
            target="_blank"
            rel="noopener nofollow"
          >
            Datenschutzgesetz (DSG)
          </LegalLink>{" "}
          zu erkl&auml;ren, welche Informationen wir sammeln, wie wir Daten
          verwenden und welche Entscheidungsm&ouml;glichkeiten Sie als Besucher
          dieser Webseite haben.
        </LegalParagraph>
        <LegalParagraph>
          Leider liegt es in der Natur der Sache, dass diese Erkl&auml;rungen
          sehr technisch klingen, wir haben uns bei der Erstellung jedoch
          bem&uuml;ht die wichtigsten Dinge so einfach und klar wie m&ouml;glich
          zu beschreiben.
        </LegalParagraph>
        <LegalHeadlines>Automatische Datenspeicherung</LegalHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen
          automatisch erstellt und gespeichert, so auch auf dieser Webseite.
        </LegalParagraph>
        <LegalParagraph>
          Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser
          Webserver (Computer auf dem diese Webseite gespeichert ist)
          automatisch Daten wie
        </LegalParagraph>
        <ul>
          <li>die Adresse (URL) der aufgerufenen Webseite</li>
          <li>Browser und Browserversion</li>
          <li>das verwendete Betriebssystem</li>
          <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
          <li>
            den Hostname und die IP-Adresse des Ger&auml;ts von welchem aus
            zugegriffen wird
          </li>
          <li>Datum und Uhrzeit</li>
        </ul>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          in Dateien (Webserver-Logfiles).
        </LegalParagraph>
        <LegalParagraph>
          In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und
          danach automatisch gel&ouml;scht. Wir geben diese Daten nicht weiter,
          k&ouml;nnen jedoch nicht ausschlie&szlig;en, dass diese Daten beim
          Vorliegen von rechtswidrigem Verhalten eingesehen werden.
          <br />
          Die Rechtsgrundlage besteht nach{" "}
          <LegalLink
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=211094248"
            target="_blank"
            rel="noopener nofollow"
          >
            Artikel 6  Absatz 1 f DSGVO
          </LegalLink>{" "}
          (Rechtm&auml;&szlig;igkeit der Verarbeitung) darin, dass berechtigtes
          Interesse daran besteht, den fehlerfreien Betrieb dieser Webseite
          durch das Erfassen von Webserver-Logfiles zu erm&ouml;glichen.
        </LegalParagraph>
        <LegalHeadlines>Cookies</LegalHeadlines>
        <LegalParagraph>
          Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu
          speichern.
          <br />
          Im Folgenden erkl&auml;ren wir, was Cookies sind und warum Sie genutzt
          werden, damit Sie die folgende Datenschutzerkl&auml;rung besser
          verstehen.
        </LegalParagraph>
        <LegalSubHeadlines>Was genau sind Cookies?</LegalSubHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser.
          Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet
          Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine
          Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Eines ist nicht von der Hand zu weisen: Cookies sind echt
          n&uuml;tzliche Helferlein. Fast alle Webseiten verwenden&nbsp;Cookies.
          Genauer gesprochen sind es HTTP-Cookies, da es auch noch anderer
          Cookies f&uuml;r andere Anwendungsbereiche gibt.
          HTTP-Cookies&nbsp;sind kleine Dateien, die von unserer Website auf
          Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden
          automatisch im Cookie-Ordner, quasi dem &bdquo;Hirn&ldquo; Ihres
          Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem
          Wert. Bei der Definition eines Cookies m&uuml;ssen zus&auml;tzlich ein
          oder mehrere Attribute angegeben werden.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Cookies&nbsp;speichern gewisse Nutzerdaten von Ihnen, wie
          beispielsweise Sprache oder pers&ouml;nliche Seiteneinstellungen. Wenn
          Sie unsere Seite wieder aufrufen, &uuml;bermittelt Ihr Browser die
          &bdquo;userbezogenen&ldquo; Informationen an unsere Seite zur&uuml;ck.
          Dank der Cookies wei&szlig; unsere Website, wer Sie sind und bietet
          Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat
          jedes&nbsp;Cookie&nbsp;eine eigene Datei, in anderen wie
          beispielsweise Firefox sind alle&nbsp;Cookies&nbsp;in einer einzigen
          Datei gespeichert.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies.
          Erstanbieter-Cookies werden direkt von unserer Seite erstellt,
          Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google
          Analytics) erstellt.&nbsp;Jedes Cookie ist individuell zu bewerten, da
          jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies
          variiert von ein paar Minuten bis hin zu ein paar Jahren.&nbsp;Cookies
          sind keine Software-Programme und enthalten keine Viren, Trojaner oder
          andere &bdquo;Sch&auml;dlinge&ldquo;. Cookies k&ouml;nnen auch nicht
          auf Informationen Ihres PCs zugreifen.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          So k&ouml;nnen zum Beispiel Cookie-Daten aussehen:
        </LegalParagraph>
        <ul>
          <li>Name: _ga</li>
          <li>Ablaufzeit: 2 Jahre</li>
          <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
          <li>Beispielhafter Wert: GA1.2.1326744211.152211094248</li>
        </ul>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Ein Browser sollte folgende Mindestgr&ouml;&szlig;en
          unterst&uuml;tzen:
        </LegalParagraph>
        <ul>
          <li>Ein Cookie soll mindestens 4096 Bytes enthalten k&ouml;nnen</li>
          <li>
            Pro Domain sollen mindestens 50 Cookies gespeichert werden
            k&ouml;nnen
          </li>
          <li>
            Insgesamt sollen mindestens 3000 Cookies gespeichert werden
            k&ouml;nnen
          </li>
        </ul>
        <LegalSubHeadlines>Welche Arten von Cookies gibt es?</LegalSubHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Die Frage welche Cookies wir im Speziellen verwenden, h&auml;ngt von
          den verwendeten Diensten ab und wird in der folgenden Abschnitten der
          Datenschutzerkl&auml;rung gekl&auml;rt. An dieser Stelle m&ouml;chten
          wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Man kann 4 Arten von Cookies unterscheiden:
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          <strong>
            Unbedingt notwendige Cookies
            <br />
          </strong>
          Diese Cookies sind n&ouml;tig, um grundlegende Funktionen der Website
          sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User
          ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft
          und sp&auml;ter erst zur Kasse geht. Durch diese Cookies wird der
          Warenkorb nicht gel&ouml;scht, selbst wenn der User sein
          Browserfenster schlie&szlig;t.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          <strong>
            Funktionelle Cookies
            <br />
          </strong>
          Diese Cookies sammeln Infos &uuml;ber das Userverhalten und ob der
          User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser
          Cookies auch die Ladezeit und das Verhalten der Website bei
          verschiedenen Browsern gemessen.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          <strong>
            Zielorientierte Cookies
            <br />
          </strong>
          Diese Cookies sorgen f&uuml;r eine bessere Nutzerfreundlichkeit.
          Beispielsweise werden eingegebene Standorte, Schriftgr&ouml;&szlig;en
          oder Formulardaten gespeichert.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          <strong>
            Werbe-Cookies
            <br />
          </strong>
          Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu
          dem User individuell angepasste Werbung zu liefern. Das kann sehr
          praktisch, aber auch sehr nervig sein.
        </LegalParagraph>
        <LegalParagraph>
          &Uuml;blicherweise werden Sie beim erstmaligen Besuch einer Webseite
          gefragt, welche dieser Cookiearten Sie zulassen m&ouml;chten. Und
          nat&uuml;rlich wird diese Entscheidung auch in einem Cookie
          gespeichert.
        </LegalParagraph>
        <LegalSubHeadlines>
          Wie kann ich Cookies l&ouml;schen?
        </LegalSubHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst.
          Unabh&auml;ngig von welchem Service oder welcher Website die Cookies
          stammen, haben Sie immer die M&ouml;glichkeit&nbsp;Cookies zu
          l&ouml;schen, nur teilweise zuzulassen oder zu deaktivieren. Zum
          Beispiel k&ouml;nnen Sie Cookies von Drittanbietern blockieren, aber
          alle anderen Cookies zulassen.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Wenn Sie feststellen m&ouml;chten, welche Cookies in Ihrem Browser
          gespeichert wurden, wenn Sie Cookie-Einstellungen &auml;ndern oder
          l&ouml;schen wollen, k&ouml;nnen Sie dies in Ihren
          Browser-Einstellungen finden:
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          <LegalLink
            href="https://support.google.com/chrome/answer/95647?tid=211094248"
            rel="nofollow"
          >
            Chrome: Cookies in Chrome l&ouml;schen, aktivieren und verwalten
          </LegalLink>
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          <LegalLink
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=211094248"
            rel="nofollow"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </LegalLink>
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          <LegalLink
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=211094248"
            rel="nofollow"
          >
            Firefox: Cookies l&ouml;schen, um Daten zu entfernen, die Websites
            auf Ihrem Computer abgelegt haben
          </LegalLink>
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          <LegalLink
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=211094248"
            rel="nofollow"
          >
            Internet Explorer: L&ouml;schen und Verwalten von Cookies
          </LegalLink>
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          <LegalLink
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=211094248"
            rel="nofollow"
          >
            Microsoft Edge: L&ouml;schen und Verwalten von Cookies
          </LegalLink>
        </LegalParagraph>
        <LegalParagraph>
          Falls Sie grunds&auml;tzlich keine Cookies haben wollen, k&ouml;nnen
          Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn
          ein Cookie gesetzt werden soll. So k&ouml;nnen Sie bei jedem einzelnen
          Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die
          Vorgangsweise ist je nach Browser verschieden. Am besten ist es Sie
          suchen die Anleitung in Google mit dem Suchbegriff &ldquo;Cookies
          l&ouml;schen Chrome&rdquo; oder &bdquo;Cookies deaktivieren
          Chrome&ldquo; im Falle eines Chrome Browsers oder tauschen das Wort
          &bdquo;Chrome&ldquo; gegen den Namen Ihres Browsers, z.B. Edge,
          Firefox, Safari aus.
        </LegalParagraph>
        <LegalSubHeadlines>
          Wie sieht es mit meinem Datenschutz aus?
        </LegalSubHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Seit 2009 gibt es die sogenannten &bdquo;Cookie-Richtlinien&ldquo;.
          Darin ist festgehalten, dass das Speichern von Cookies eine
          Einwilligung des Website-Besuchers (also von Ihnen) verlangt.
          Innerhalb der EU-L&auml;nder gibt es allerdings noch sehr
          unterschiedliche Reaktionen auf diese Richtlinien. In &Ouml;sterreich
          erfolgte aber die Umsetzung dieser Richtlinie in &sect; 96 Abs. 3 des
          Telekommunikationsgesetzes (TKG).
        </LegalParagraph>
        <LegalParagraph>
          Wenn Sie mehr &uuml;ber Cookies wissen m&ouml;chten und vor
          technischen Dokumentationen nicht zur&uuml;ckscheuen, empfehlen
          wir&nbsp;
          <LegalLink
            href="https://tools.ietf.org/html/rfc6265"
            target="_blank"
            rel="nofollow noopener"
          >
            https://tools.ietf.org/html/rfc6265
          </LegalLink>
          , dem Request for Comments der Internet Engineering Task Force (IETF)
          namens „HTTP State Management Mechanism“.
        </LegalParagraph>
        <LegalHeadlines>Speicherung persönlicher Daten</LegalHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Pers&ouml;nliche Daten, die Sie uns auf dieser Website elektronisch
          &uuml;bermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder
          andere pers&ouml;nlichen Angaben im Rahmen der &Uuml;bermittlung eines
          Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem
          Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck
          verwendet, sicher verwahrt und nicht an Dritte weitergegeben.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Wir nutzen Ihre pers&ouml;nlichen Daten somit nur f&uuml;r die
          Kommunikation mit jenen Besuchern, die Kontakt ausdr&uuml;cklich
          w&uuml;nschen und f&uuml;r die Abwicklung der auf dieser Webseite
          angebotenen Dienstleistungen und Produkte. Wir geben Ihre
          pers&ouml;nlichen Daten ohne Zustimmung nicht weiter, k&ouml;nnen
          jedoch nicht ausschlie&szlig;en, dass diese Daten beim Vorliegen von
          rechtswidrigem Verhalten eingesehen werden.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Wenn Sie uns pers&ouml;nliche Daten per E-Mail schicken &ndash; somit
          abseits dieser Webseite &ndash; k&ouml;nnen wir keine sichere
          &Uuml;bertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen
          Ihnen, vertrauliche Daten niemals unverschl&uuml;sselt per E-Mail zu
          &uuml;bermitteln.
        </LegalParagraph>
        <LegalParagraph>
          Die Rechtsgrundlage besteht nach{" "}
          <LegalLink
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=211094248"
            target="_blank"
            rel="noopener nofollow"
          >
            Artikel 6  Absatz 1 a DSGVO
          </LegalLink>{" "}
          (Rechtm&auml;&szlig;igkeit der Verarbeitung) darin, dass Sie uns die
          Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben.
          Sie k&ouml;nnen diesen Einwilligung jederzeit widerrufen &ndash; eine
          formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im
          Impressum.
        </LegalParagraph>
        <LegalHeadlines>Rechte laut Datenschutzgrundverordnung</LegalHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Ihnen stehen laut den Bestimmungen der DSGVO und des
          &ouml;sterreichischen{" "}
          <LegalLink
            href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=211094248"
            target="_blank"
            rel="noopener nofollow"
          >
            Datenschutzgesetzes (DSG)
          </LegalLink>{" "}
          grunds&auml;tzlich die folgende Rechte zu:
        </LegalParagraph>
        <ul>
          <li>Recht auf Berichtung (Artikel 16 DSGVO)</li>
          <li>
            Recht auf L&ouml;schung (&bdquo;Recht auf Vergessenwerden&ldquo;)
            (Artikel 17 DSGVO)
          </li>
          <li>
            Recht auf Einschr&auml;nkung der Verarbeitung (Artikel 18 DSGVO)
          </li>
          <li>
            Recht auf Benachrichtigung &ndash; Mitteilungspflicht im
            Zusammenhang mit der Berichtigung oder L&ouml;schung
            personenbezogener Daten oder der Einschr&auml;nkung der Verarbeitung
            (Artikel 19 DSGVO)
          </li>
          <li>Recht auf Daten&uuml;bertragbarkeit (Artikel 20 DSGVO)</li>
          <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
          <li>Recht auf Daten&uuml;bertragbarkeit (Artikel 20 DSGVO)</li>
        </ul>
        <LegalParagraph>
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
          Datenschutzrecht verst&ouml;&szlig;t oder Ihre datenschutzrechtlichen
          Anspr&uuml;che sonst in einer Weise verletzt worden sind, k&ouml;nnen
          Sie sich bei der Aufsichtsbeh&ouml;rde beschweren, welche in
          &Ouml;sterreich die Datenschutzbeh&ouml;rde ist, deren Webseite Sie
          unter{" "}
          <LegalLink href="https://www.dsb.gv.at/?tid=211094248" rel="nofollow">
            https://www.dsb.gv.at/
          </LegalLink>{" "}
          finden.
        </LegalParagraph>
        <LegalHeadlines>Auswertung des Besucherverhaltens</LegalHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          In der folgenden Datenschutzerkl&auml;rung informieren wir Sie
          dar&uuml;ber, ob und wie wir Daten Ihres Besuchs dieser Website
          auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel
          anonym und wir k&ouml;nnen von Ihrem Verhalten auf dieser Website
          nicht auf Ihre Person schlie&szlig;en.
        </LegalParagraph>
        <LegalParagraph>
          Mehr &uuml;ber M&ouml;glichkeiten dieser Auswertung der Besuchsdaten
          zu widersprechen erfahren Sie in der folgenden
          Datenschutzerkl&auml;rung.
        </LegalParagraph>
        <LegalHeadlines>TLS-Verschl&uuml;sselung mit https</LegalHeadlines>
        <LegalParagraph>
          Wir verwenden https um Daten abh&ouml;rsicher im Internet zu
          &uuml;bertragen (Datenschutz durch Technikgestaltung{" "}
          <LegalLink
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=211094248"
            target="_blank"
            rel="noopener nofollow"
          >
            Artikel 25 Absatz 1 DSGVO
          </LegalLink>
          ). Durch den Einsatz von TLS (Transport Layer Security), einem
          Verschl&uuml;sselungsprotokoll zur sicheren Daten&uuml;bertragung im
          Internet k&ouml;nnen wir den Schutz vertraulicher Daten sicherstellen.
          Sie erkennen die Benutzung dieser Absicherung der
          Daten&uuml;bertragung am kleinen Schlosssymbol links oben im Browser
          und der Verwendung des Schemas https (anstatt http) als Teil unserer
          Internetadresse.
        </LegalParagraph>
        <LegalHeadlines>
          Google Analytics Datenschutzerkl&auml;rung
        </LegalHeadlines>
        <LegalParagraph>
          WWir verwenden auf dieser Website Google Analytics der Firma Google
          LLC (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) um
          Besucherdaten statistisch auszuwerten. Dabei verwendet Google
          Analytics zielorientierte Cookies.
        </LegalParagraph>
        <LegalSubHeadlines>Cookies von Google Analytics</LegalSubHeadlines>
        <ul>
          <li>
            _ga
            <ul>
              <li>Ablaufzeit: 2 Jahre</li>
              <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
              <li>Beispielhafter Wert: GA1.2.1326744211.152211094248</li>
            </ul>
          </li>
          <li>
            _gid
            <ul>
              <li>Ablaufzeit: 24 Stunden</li>
              <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
              <li>Beispielhafter Wert: GA1.2.1687193234.152211094248</li>
            </ul>
          </li>
          <li>
            _gat_gtag_UA_{"<property-id>"}
            <ul>
              <li>Ablaufzeit: 1 Minute</li>
              <li>
                Verwendung:&nbsp;Wird zum Drosseln der Anforderungsrate
                verwendet. Wenn Google Analytics &uuml;ber den Google Tag
                Manager bereitgestellt wird, erh&auml;lt dieser Cookie den Namen
                _dc_gtm_ {"<property-id>"}.
              </li>
              <li>Beispielhafter Wert: 1</li>
            </ul>
          </li>
        </ul>
        <LegalParagraph>
          N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz
          finden Sie unter{" "}
          <LegalLink
            href="http://www.google.com/analytics/terms/de.html"
            rel="nofollow"
          >
            http://www.google.com/analytics/terms/de.html
          </LegalLink>{" "}
          bzw. unter{" "}
          <LegalLink
            href="https://support.google.com/analytics/answer/6004245?hl=de"
            rel="nofollow"
          >
            https://support.google.com/analytics/answer/6004245?hl=de
          </LegalLink>
          .
        </LegalParagraph>
        <LegalSubHeadlines>Pseudonymisierung</LegalSubHeadlines>
        <LegalParagraph>
          Unser Anliegen im Sinne der DSGVO ist die Verbesserung unseres
          Angebotes und unseres Webauftritts. Da uns die Privatsph&auml;re
          unserer Nutzer wichtig ist, werden die Nutzerdaten
          pseudonymisiert.&nbsp;Die Datenverarbeitung erfolgt auf Basis der
          gesetzlichen Bestimmungen des &sect; 96 Abs 3 TKG sowie des Art 6
          EU-DSGVO Abs 1 lit a (Einwilligung) und/oder f (berechtigtes
          Interesse) der DSGVO.
        </LegalParagraph>
        <LegalSubHeadlines>
          Deaktivierung der Datenerfassung durch Google Analytics
        </LegalSubHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Mithilfe des <strong>Browser-Add-ons zur Deaktivierung</strong> von
          Google Analytics-JavaScript (ga.js, analytics.js, dc.js) k&ouml;nnen
          Website-Besucher verhindern, dass Google Analytics ihre Daten
          verwendet.
        </LegalParagraph>
        <LegalParagraph>
          Sie k&ouml;nnen die Erfassung der durch das Cookie erzeugten und auf
          Ihre Nutzung der Website bezogenen Daten an Google sowie die
          Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter
          dem folgenden Link verf&uuml;gbare Browser-Plugin herunterladen und
          installieren:{" "}
          <LegalLink
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            rel="nofollow"
          >
            https://tools.google.com/dlpage/gaoptout?hl=de
          </LegalLink>
        </LegalParagraph>
        <LegalSubHeadlines>
          Google Analytics Zusatz zur Datenverarbeitung
        </LegalSubHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Wir haben mit Google einen Direktkundenvertrag zur Verwendung von
          Google Analytics abgeschlossen, indem wir den &ldquo;Zusatz zur
          Datenverarbeitung&rdquo; in Google Analytics akzeptiert haben.
        </LegalParagraph>
        <LegalParagraph>
          Mehr &uuml;ber den Zusatz zur Datenverarbeitung f&uuml;r Google
          Analytics finden Sie hier:{" "}
          <LegalLink
            href="https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad"
            target="_blank"
            rel="noopener nofollow"
          >
            https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad
          </LegalLink>
        </LegalParagraph>
        <LegalHeadlines>Google Analytics IP-Anonymisierung</LegalHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von
          Google Analytics implementiert.&nbsp;Diese Funktion wurde von Google
          entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen
          und Empfehlungen der lokalen Datenschutzbeh&ouml;rden einhalten kann,
          wenn diese eine Speicherung der vollst&auml;ndigen IP-Adresse
          untersagen. Die Anonymisierung bzw. Maskierung der IP findet statt,
          sobald die IP-Adressen im Google Analytics-Datenerfassungsnetzwerk
          eintreffen und bevor eine Speicherung oder Verarbeitung der Daten
          stattfindet.
        </LegalParagraph>
        <LegalParagraph>
          Mehr Informationen zur IP-Anonymisierung finden Sie auf 
          <LegalLink
            href="https://support.google.com/analytics/answer/2763052?hl=de"
            target="_blank"
            rel="noopener nofollow"
          >
            https://support.google.com/analytics/answer/2763052?hl=de
          </LegalLink>
          .
        </LegalParagraph>
        <LegalHeadlines>
          Google Analytics Berichte zu demografischen Merkmalen und Interessen
        </LegalHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Wir haben in Google Analytics die Funktionen f&uuml;r Werbeberichte
          eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen
          enthalten Angaben zu Alter, Geschlecht und Interessen. Damit
          k&ouml;nnen wir uns &ndash; ohne diese Daten einzelnen Personen
          zuordnen zu k&ouml;nnen &ndash; ein besseres Bild von unseren Nutzern
          machen. Mehr &uuml;ber die Werbefunktionen erfahren Sie{" "}
          <LegalLink
            href="https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad"
            target="_blank"
            rel="noopener nofollow"
          >
            auf https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad
          </LegalLink>
          .
        </LegalParagraph>
        <LegalParagraph>
          Sie k&ouml;nnen die Nutzung der Aktivit&auml;ten und Informationen
          Ihres Google Kontos unter &ldquo;Einstellungen f&uuml;r Werbung&rdquo;
          auf{" "}
          <LegalLink
            href="https://adssettings.google.com/authenticated"
            rel="nofollow"
          >
            https://adssettings.google.com/authenticated
          </LegalLink>{" "}
          per Checkbox beenden.
        </LegalParagraph>
        <LegalHeadlines>Google Analytics Deaktivierungslink</LegalHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.s }}>
          Wenn Sie auf folgenden <strong>Deaktivierungslink</strong> klicken,
          können Sie verhindern, dass Google weitere Besuche auf dieser Webseite
          erfasst. Achtung: Das Löschen von Cookies, die Nutzung des
          Inkognito/Privatmodus ihres Browsers, oder die Nutzung eines anderen
          Browsers führt dazu, dass wieder Daten erhoben werden.
        </LegalParagraph>
        <LegalParagraph style={{ margin: spaces.stack.xl }}>
          <LegalLink href={"javascript:gaoop_analytics_optout();"}>
            Google Analytics deaktivieren
          </LegalLink>
        </LegalParagraph>

        <LegalParagraph style={{ color: color.font.veryLight }}>
          Quelle: Erstellt mit dem 
          <LegalLink
            title="Datenschutz Generator von firmenwebseiten.at"
            href="https://www.firmenwebseiten.at/datenschutz-generator/"
            target="_blank"
            rel="follow"
            style={{ color: color.font.veryLight }}
          >
            Datenschutz Generator von firmenwebseiten.at
          </LegalLink>
           in Kooperation mit{" "}
          <LegalLink
            href="https://www.metawissen.at"
            target="_blank"
            rel="follow"
            title=""
            style={{ color: color.font.veryLight }}
          >
            metawissen.at
          </LegalLink>
        </LegalParagraph>
      </LegalContentRow>
    </>
  )
}

export default PrivacyStatement
